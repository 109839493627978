import React, { useState, useEffect } from "react"
import Container from "@material-ui/core/Container"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Typography } from "@material-ui/core"
import InfiniteScroll from "react-infinite-scroll-component"
import fetch from "node-fetch"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { Article } from "../ts/interfaces"
import Icon from "../components/ui/Icon"
import { runFreshChatLogic, runFreshDeskLogic } from "../utils/freshDesk"

const TopBanner = styled.div`
  position: relative;
  background: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 15px 30px -15px rgb(27 31 34 / 12%);
  /* top: 78px; */
  /* height: 320px; */
`

const BlogTitle = styled.h1`
  color: transparent;
  background: var(--color-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  margin-top: 78px;
  margin-bottom: 160px;

  @media (max-width: 900px) {
    margin-top: 36px;
    margin-bottom: 100px;
  }
`

const CategoriesBar = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  @media (max-width: 900px) {
    width: 80%;
    bottom: 24px;
  }
`

const DesktopWrapper = styled.div`
  display: flex;
  @media (max-width: 900px) {
    display: none;
  }
`

const ArticleCategory = styled(Typography)<{ active: boolean }>`
  position: relative;
  margin: 0 32px;
  font-weight: bold;
  font-family: var(--font-family-header);
  cursor: pointer;
  line-height: 64px;
  color: ${({ active }) => (active ? "transparent" : "var(--color-grey-3)")};
  background: ${({ active }) => (active ? "var(--color-gradient)" : "initial")};
  background-clip: ${({ active }) => (active ? "text" : "initial")};
  -webkit-background-clip: ${({ active }) => (active ? "text" : "initial")};
  text-fill-color: ${({ active }) => (active ? "transparent" : "initial")};

  ${({ active }) =>
    active &&
    `
  &::after {
    content: "";
    position: absolute;
    background: var(--color-gradient);
    bottom: 0;
    left: 0;
    height: 6px;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
`}
`

const ArticlesContainer = styled(Container)`
  /* margin-top: 352px; */
`

const ArticlesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
`

const ArticleCard = styled(Link)`
  display: flex;
  flex-direction: column;
  margin: 0 16px 16px;
  border-radius: 12px;
  background: white;
  box-shadow: 0px 4px 8px rgba(27, 31, 34, 0.15);
  width: 100%;
  font-family: var(--font-family-header);
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  color: initial;
  box-sizing: border-box;
  flex-basis: calc(33% - 24px);
  margin: 12px;
  &:hover,
  :visited {
    text-decoration: none;
    color: initial;
  }

  @media (max-width: 900px) {
    flex-basis: calc(50% - 24px);
  }

  @media (max-width: 670px) {
    flex-basis: calc(100% - 24px);
  }
`

const ImageWrapper = styled.div`
  height: 220px;
  width: 100%;
`
const ArticleImage = styled.div<{ imageUrl: string }>`
  background-image: ${({ imageUrl }) => imageUrl && `url("${imageUrl}")`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`

const CardContent = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`

const CardHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const AuthorWrapper = styled.div`
  display: flex;
  align-items: center;
`

const AuthorAvatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
`

const AuthorName = styled.p`
  font-size: 14px;
  color: var(--color-grey-3);
`

const CategorySelect = styled(props => <Select {...props} />)`
  display: none;
  @media (max-width: 900px) {
    display: block;
    box-shadow: 0px 4px 8px rgba(27, 31, 34, 0.15);
    border-radius: 24px;
    .MuiSelect-select.MuiSelect-select {
      box-sizing: border-box;
      padding: 12px 24px;
      border-radius: 24px;
      border: 2px solid var(--color-grey-2);

      color: transparent;
      background: var(--color-gradient);
      background-size: 50%;
      background-clip: text;
      -webkit-background-clip: text;
      text-fill-color: transparent;
      font-weight: bold;
    }
    .MuiSelect-icon {
      right: 24px;
    }
    &::before,
    &::after {
      display: none;
    }
  }
`

const Blog = () => {
  const { strapi } = useStaticQuery(graphql`
    query {
      strapi {
        freshDeskWidget {
          freshDeskTestId
          freshDeskId
          openOnLoad
          pages {
            slug
          }
        }
        freshChatWidget {
          freshChatToken
          openOnLoad
          pages {
            slug
          }
        }
        articleTags {
          tagName
        }
        articles(sort: "published_at:desc") {
          id
          published_at
          previewOnly
          title
          content {
            id
            content
            align
            maxWidth
            largeFormat
            inlineMedia {
              referenceName
              rounded
              align
              width
              youtubeId
              youtubeMobileId
              image {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
              mobileImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            buttons {
              button {
                id
                title
                type
                link {
                  __typename
                  ... on STRAPI_ComponentNavigationMainNav {
                    navItemPage {
                      id
                      slug
                    }
                    navItemLandingPage {
                      id
                      slug
                    }
                    navItemName
                  }
                  ... on STRAPI_ComponentNavigationProduct {
                    id
                    addToCart
                    product {
                      id
                      stripeId
                      stripeTestId
                      slug
                    }
                    linkText
                  }
                  ... on STRAPI_ComponentNavigationHyperlink {
                    url
                  }
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
              }
            }
            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            featuredImage {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    quality: 70
                  )
                }
              }
            }
            featuredVideo {
              youtubeId
              youtubeMobileId
            }
          }
          slug
          image {
            url
            alternativeText
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: FULL_WIDTH
                  quality: 80
                )
              }
            }
          }
          articleTag {
            tagName
          }
          author {
            id
            firstName
            lastName
            avatar {
              url
            }
          }
          seo {
            id
            metaTitle
            metaDescription
            metaImage {
              url
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FIXED
                    quality: 80
                    width: 1200
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    articles,
    articleTags,
    freshDeskWidget: {
      freshDeskId,
      freshDeskTestId,
      pages: freshDeskPages,
      openOnLoad: freshDeskOpenOnLoad,
    },
    freshChatWidget: {
      freshChatToken,
      pages: freshChatPages,
      openOnLoad: freshChatOpenOnLoad,
    },
  }: {
    articles: Article[]
    articleTags: { tagName: string }[]
    freshDeskWidget: {
      freshDeskTestId?: string
      freshDeskId?: string
      openOnLoad: boolean
      pages: {
        slug?: string
      }[]
    }
    freshChatWidget: {
      freshChatToken?: string
      openOnLoad: boolean
      pages: {
        slug?: string
      }[]
    }
  } = strapi

  const filteredArticles = articles.filter(article => {
    if (article.previewOnly) {
      if (process.env.GATSBY_STRIPE_ENVIRONMENT === "development") {
        return true
      }
      return false
    }
    return true
  })

  const articleCategories = articleTags.map(tag => tag.tagName)

  articleCategories.unshift("latest") // add a latest category to the list

  const [active, setActive] = useState(articleCategories[0])

  useEffect(() => {
    const freshDeskPagesFormatted = freshDeskPages.map(page => page.slug!) || []
    runFreshDeskLogic({
      currentPageSlug: "blog",
      freshDeskPages: freshDeskPagesFormatted,
      freshDeskId,
      freshDeskTestId,
      openOnLoad: freshDeskOpenOnLoad,
    })

    const freshChatPagesFormatted = freshChatPages.map(page => page.slug!) || []

    runFreshChatLogic({
      currentPageSlug: "blog",
      freshDeskPages: freshChatPagesFormatted,
      freshChatToken,
      openOnLoad: freshChatOpenOnLoad,
    })
  }, [])

  return (
    <Layout hasHero={false}>
      <SEO title="Blog" />
      <TopBanner>
        <BlogTitle>SENS.AI BLOG</BlogTitle>
        <CategoriesBar>
          <DesktopWrapper>
            {articleCategories &&
              articleCategories.map((category, index) => (
                <ArticleCategory
                  key={`article-category--${index}`}
                  active={active === category}
                  onClick={() => setActive(category)}
                >
                  {category.toUpperCase()}
                </ArticleCategory>
              ))}
          </DesktopWrapper>
          <CategorySelect
            fullWidth
            required
            labelId="position-select"
            name="position"
            value={active}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              e && setActive(e.target.value)
            }
          >
            {articleCategories &&
              articleCategories.map((category, index) => (
                <MenuItem key={`article-category--${index}`} value={category}>
                  {category.toUpperCase()}
                </MenuItem>
              ))}
          </CategorySelect>
        </CategoriesBar>
      </TopBanner>
      <ArticlesContainer>
        <ArticlesWrapper>
          {filteredArticles &&
            filteredArticles
              .filter(article =>
                active === "latest"
                  ? article
                  : article?.articleTag?.tagName === active
              )
              .map((article, index: number) => (
                <ArticleCard
                  to={`/blog/${article?.slug}`}
                  key={`article--${index}`}
                >
                  <ImageWrapper>
                    <ArticleImage imageUrl={article?.image?.url} />
                  </ImageWrapper>
                  <CardContent>
                    <CardHeading>
                      <AuthorWrapper>
                        <AuthorAvatar
                          src={article?.author?.avatar?.url}
                          alt={"author avatar"}
                        />
                        <AuthorName>
                          {article?.author?.firstName}{" "}
                          {article?.author?.lastName}
                        </AuthorName>
                      </AuthorWrapper>
                      <Icon iconType="Solid" iconName="arrow-right" />
                    </CardHeading>
                    {article.title}
                  </CardContent>
                </ArticleCard>
              ))}
        </ArticlesWrapper>
      </ArticlesContainer>
    </Layout>
  )
}

export default Blog
